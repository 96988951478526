import React, { useState, useEffect } from 'react';
import './App.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import TopicsPanel from './TopicsPanel';

const removeLeadingNumber = (fact) => {
  const trimmedFact = fact.trim();
  return trimmedFact.replace(/^\d+\.\s*/, '').trim() || trimmedFact;
};

function App() {
  const [facts, setFacts] = useState([]);
  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [topic, setTopic] = useState('');
  const [currentTopic, setCurrentTopic] = useState('');

  const fetchFacts = async (topic) => {
    setIsLoading(true);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/facts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ topic }),
    });
    let data = await response.json();
    console.log('Raw facts received:', data); // Debug log
    data = data.map(fact => removeLeadingNumber(fact.trim())).filter(fact => fact !== '');
    console.log('Number of processed facts:', data.length); // Debug log
    console.log('Processed facts:', data); // Debug log
    setFacts(data);
    setCurrentFactIndex(0);
    setCurrentTopic(topic);
    setIsLoading(false);
  };

  /*const generateAdditionalFacts = async () => {
    setIsLoading(true);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/facts`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ topic: currentTopic, existing_facts: facts }),
    });
    const newFacts = await response.json();
    const processedNewFacts = newFacts
      .map(fact => removeLeadingNumber(fact.trim()))
      .filter(fact => fact !== '');
    setFacts(prevFacts => [...prevFacts, ...processedNewFacts]);
    setIsLoading(false);
  };*/

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        e.preventDefault();  // Prevent default scrolling behavior
        if (e.key === 'ArrowLeft') {
          setCurrentFactIndex((prev) => (prev > 0 ? prev - 1 : facts.length - 1));
        } else {
          setCurrentFactIndex((prev) => (prev < facts.length - 1 ? prev + 1 : 0));
        }
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [facts.length]);

  return (
    <div className="App">
      <header className="App-header">
        <TopicsPanel onSelectTopic={(selectedTopic) => {
          setTopic(selectedTopic);
          fetchFacts(selectedTopic);
        }} />
        
        <input
          type="text"
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          placeholder="Enter a topic"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              fetchFacts(e.target.value);
            }
          }}
        />
        <button onClick={() => fetchFacts(topic)}>Generate Flashcards</button>
        {isLoading && <p>Loading...</p>}

        <div className="swipe-container" style={{ pointerEvents: 'auto' }}>
          {facts.length > 0 && (
            <Carousel
              selectedItem={currentFactIndex}
              onChange={(index) => setCurrentFactIndex(index)}
              swipeable
              emulateTouch
              showThumbs={false}
              showStatus={false}
              showArrows={false}
              useKeyboardArrows
              swipeScrollTolerance={10}
              infiniteLoop={true}
            >
              {facts.map((fact, index) => (
                fact && (
                  <div key={index} className="flashcard-box">
                    <p>{`${index + 1}. ${fact}`}</p>
                  </div>
                )
              ))}
            </Carousel>
          )}
        </div>

        {/*facts.length > 0 && (
          <button onClick={generateAdditionalFacts} className="generate-more-button">
            Generate Additional Flashcards
          </button>
        )*/}
      </header>
    </div>
  );
}

export default App;