import React, { useState, useEffect } from 'react';
import './App.css'; // Ensure you import your CSS file

function TopicsPanel({ onSelectTopic }) {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false); // State to track loading

  useEffect(() => {
    async function fetchTopics() {
      setLoading(true); // Start loading
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/topics`);
      const data = await response.json();
      setTopics(data);
      setLoading(false); // Stop loading
    }

    fetchTopics();
  }, []);

  return (
    <div className="topics-container">
      {loading ? (
        <p>Loading topics...</p> // Display loading message
      ) : (
        topics.map((topic, index) => (
          <button key={index} onClick={() => onSelectTopic(topic)} className="topic-button">
            {topic}
          </button>
        ))
      )}
    </div>
  );
}

export default TopicsPanel;